import React, {useEffect} from 'react';
import { Link, useLocation, useParams, useRoutes } from 'react-router-dom';
import { gql, useQuery } from '@apollo/client';
import { Menu } from '@headlessui/react'
import { CogIcon, SwitchHorizontalIcon } from '@heroicons/react/solid'
import cx from 'clsx'
import { CompanyRouteQuery, CompanyRouteQuery_company } from '../__generated__/CompanyRouteQuery';
import { Topbar, TopbarNavLink } from '../components/Topbar';
import BotModulesCloneRoute from './BotModulesCloneRoute';
import BotModulesCreateRoute from './BotModulesCreateRoute';
import BotModulesDetailsRoute from './BotModulesDetailsRoute';
import BotModulesOverviewRoute from './BotModulesOverviewRoute';
import BotModulesUpdateRoute from './BotModulesUpdateRoute';
import BotUsersDetailsRoute from './BotUsersDetailsRoute';
import BotUsersOverviewRoute from './BotUsersOverviewRoute';
import BotUsersCreateRoute from './BotUsersCreateRoute';
import DashboardRoute from './DashboardRoute';
import DepartmentsCreateRoute from './DepartmentsCreateRoute';
import DepartmentsDetailsRoute from './DepartmentsDetailsRoute';
import DepartmentsOverviewRoute from './DepartmentsOverviewRoute';
import DepartmentsUpdateRoute from './DepartmentsUpdateRoute';
import FoldersCreateRoute from './FoldersCreateRoute';
import FoldersUpdateRoute from './FoldersUpdateRoute';
import SettingsRoute from './SettingsRoute';
import ThemesCreateRoute from './ThemesCreateRoute';
import ThemesOverviewRoute from './ThemesOverviewRoute';
import ThemesUpdateRoute from './ThemesUpdateRoute';
import WhatsappBroadcastsOverviewRoute from './WhatsappBroadcastsOverviewRoute';
import WhatsappBroadcastsSendRoute from './WhatsappBroadcastsSendRoute';
import QuestionAnalyticsRoute from './QuestionAnalyticsRoute';
import QaModulesOverviewRoute from "./QaModulesOverviewRoute";
import QaModulesCreateRoute from "./QaModulesCreateRoute";
import QaModulesDetailsRoute from "./QaModulesDetailsRoute";
import QaModulesUpdateRoute from "./QaModulesUpdateRoute";
import BotModuleDepartmentsUpdateRoute from "./BotModuleDepartmentsUpdateRoute";
import UsersOverviewRoute from "./UsersOverviewRoute";
import UsersCreateRoute from "./UsersCreateRoute";
import CompaniesCreateRoute from "./CompaniesCreateRoute";
import UsersAttachRoute from "./UsersAttachRoute";
import SubscriptionsCreateRoute from "./SubscriptionsCreateRoute";
import SubscriptionsUpdateRoute from "./SubscriptionsUpdateRoute";
import {useTranslation} from "react-i18next";
import DepartmentsManageBotUsersRoute from "./DepartmentsManageBotUsersRoute";
import DepartmentsManageShopCompaniesRoute from "./DepartmentsManageShopCompaniesRoute";
import DepartmentsEditVisualsRoute from "./DepartmentsEditVisualsRoute";
import DepartmentsImportBotUsersRoute from "./DepartmentsImportBotUsersRoute";
import OrdersOverviewRoute from "./OrdersOverviewRoute";
import DepartmentLogs from './DepartmentsLogs';

interface RoutesProps {
  company: CompanyRouteQuery_company
  userRole: string | null
}

const Routes: React.VFC<RoutesProps> = ({ company , userRole}) => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  let routeList = [

    { path: '/', element: <DashboardRoute userRole={userRole}  companyId={company.id}/>, index: true },

    { path: '/bot_modules', element: <BotModulesOverviewRoute userRole={userRole || ''} companyId={company.id} /> },
    { path: '/bot_modules/:botModuleId', element: <BotModulesDetailsRoute companyId={company.id} /> },
    { path: '/bot_modules/:botModuleId/cards/:cardId', element: <BotModulesDetailsRoute companyId={company.id} /> },
    { path: '/bot_modules/:botModuleId/clone', element: <BotModulesOverviewRoute userRole={userRole || ''} companyId={company.id} /> },
    { path: '/bot_modules/:botModuleId/edit', element: <BotModulesOverviewRoute userRole={userRole || ''} companyId={company.id} /> },
    { path: '/bot_modules/new', element: <BotModulesCreateRoute companyId={company.id} /> },

    { path: '/qa_modules', element: <QaModulesOverviewRoute companyId={company.id} /> },
    { path: '/qa_modules/:qaModuleId', element: <QaModulesDetailsRoute companyId={company.id} /> },
    { path: '/qa_modules/:qaModuleId/cards/:cardId', element: <QaModulesDetailsRoute companyId={company.id} /> },
  ]

  if (userRole == 'admin' || userRole == 'superadmin' || userRole == 'supervisor'){
    if (userRole == 'admin' || userRole == 'superadmin'){
      routeList.push({ path: '/orders', element: <OrdersOverviewRoute userRole={userRole || ''} companyId={company.id} /> })

      routeList.push({ path: '/bot_users/:botUserId', element: <BotUsersDetailsRoute userRole={userRole} companyId={company.id} /> })
      routeList.push({ path: '/departments', element: <DepartmentsOverviewRoute userRole={userRole || ''} companyId={company.id} /> })
      routeList.push({ path: '/departments/:departmentId', element: <DepartmentsDetailsRoute companyId={company.id} /> })
      routeList.push({ path: '/departments/:departmentId/edit', element: <DepartmentsOverviewRoute userRole={userRole || ''} companyId={company.id} /> })
      routeList.push({ path: '/departments/:departmentId/logs', element: <DepartmentLogs userRole={userRole || ''} companyId={company.id} /> })

      routeList.push({ path: '/departments/:departmentId/edit_visuals', element: <DepartmentsEditVisualsRoute companyId={company.id} /> })
      routeList.push({ path: '/departments/new', element: <DepartmentsOverviewRoute userRole={userRole || ''} companyId={company.id} /> })

      routeList.push({ path: '/folders/:folderId', element: <BotModulesOverviewRoute userRole={userRole || ''} companyId={company.id} /> })
      routeList.push({ path: '/folders/new', element: <BotModulesOverviewRoute userRole={userRole || ''} companyId={company.id} /> })

      routeList.push({ path: '/themes', element: <ThemesOverviewRoute companyId={company.id} /> })
      routeList.push({ path: '/themes/:themeId/edit', element: <ThemesOverviewRoute companyId={company.id} /> })
      routeList.push({ path: '/themes/new', element: <ThemesOverviewRoute companyId={company.id} /> })

      routeList.push({ path: '/settings', element: <SettingsRoute companyId={company.id} /> })

      routeList.push({ path: '/whatsapp_broadcasts', element: <WhatsappBroadcastsOverviewRoute userRole={userRole || ''} companyId={company.id} /> })
      routeList.push({ path: '/whatsapp_broadcasts/send', element: <WhatsappBroadcastsOverviewRoute userRole={userRole || ''} companyId={company.id} /> })

      routeList.push({ path: '/departments/:departmentId/import_bot_users', element: <DepartmentsImportBotUsersRoute companyId={company.id} /> })
    }

    routeList.push({ path: '/bot_users', element: <BotUsersOverviewRoute userRole={userRole || ''} companyId={company.id} /> })
    routeList.push({ path: '/question_analytics', element: <QuestionAnalyticsRoute userRole={userRole || ''} companyId={company.id} /> })
  }

  if (userRole == 'superadmin') {
    routeList.push({path: '/users', element: <UsersOverviewRoute companyId={company.id}/>})
  }

  const routes = useRoutes(routeList, state?.backgroundLocation || location)

  return routes
}

interface ModalRoutesProps {
  company: CompanyRouteQuery_company
  userRole: string | null
}

const ModalRoutes: React.VFC<ModalRoutesProps> = ({ company , userRole}) => {
  const location = useLocation();
  const state = location.state as { backgroundLocation?: Location };

  let routeList = [
    { path: '/bot_modules/:botModuleId/clone', element: <BotModulesCloneRoute companyId={company.id} /> },
    { path: '/bot_modules/:botModuleId/edit', element: <BotModulesUpdateRoute companyId={company.id} /> },
    { path: '/bot_modules/new', element: <BotModulesCreateRoute companyId={company.id} /> },

    { path: '/qa_modules/new', element: <QaModulesCreateRoute companyId={company.id} /> },
    { path: '/qa_modules/:qaModuleId/edit', element: <QaModulesUpdateRoute companyId={company.id} /> },
  ]

  if (userRole == 'admin' || userRole == 'superadmin') {
    routeList.push({ path: '/bot_users/new', element: <BotUsersCreateRoute companyId={company.id} /> })

    routeList.push({ path: '/users/new', element: <UsersCreateRoute companyId={company.id} />  })
    routeList.push({ path: '/users/attach', element: <UsersAttachRoute companyId={company.id} />  })

    routeList.push({ path: '/departments/:departmentId/edit', element: <DepartmentsUpdateRoute userRole={userRole} companyId={company.id} /> })
    routeList.push({ path: '/departments/:departmentId/manage_bot_users', element: <DepartmentsManageBotUsersRoute companyId={company.id} /> })
    routeList.push({ path: '/departments/:departmentId/manage_shop_companies', element: <DepartmentsManageShopCompaniesRoute companyId={company.id} /> })

    routeList.push({ path: '/departments/new', element: <DepartmentsCreateRoute userRole={userRole} companyId={company.id} /> })
    routeList.push({ path: '/departments/:departmentId/bot_modules/:botModuleId/edit', element: <BotModuleDepartmentsUpdateRoute companyId={company.id} /> })

    routeList.push({ path: '/folders/:folderId', element: <FoldersUpdateRoute companyId={company.id} /> })
    routeList.push({ path: '/folders/:folderId/edit', element: <FoldersUpdateRoute companyId={company.id} /> })
    routeList.push({ path: '/folders/new', element: <FoldersCreateRoute companyId={company.id} /> })

    routeList.push({ path: '/themes/:themeId/edit', element: <ThemesUpdateRoute companyId={company.id} /> })
    routeList.push({ path: '/themes/new', element: <ThemesCreateRoute companyId={company.id} /> })

    routeList.push({ path: '/whatsapp_broadcasts/send', element: <WhatsappBroadcastsSendRoute companyId={company.id} /> })
  }

  if (userRole == 'superadmin') {
    routeList.push({ path: '/subscriptions/new', element: <SubscriptionsCreateRoute companyId={company.id} />  })
    routeList.push({ path: '/subscriptions/:subscriptionId/edit', element: <SubscriptionsUpdateRoute companyId={company.id} />  })
  }

  const routes = useRoutes(routeList)

  if (!state?.backgroundLocation) {
    return null
  }
  return routes
}

const CompanyRoute: React.VFC = () => {
  const { companyId } = useParams<'companyId'>();
  const location = useLocation();
  const { t, i18n } = useTranslation();

  const [showPopup, setShowPopup] = React.useState<string | null>(null);

  const { data, loading, error } = useQuery<CompanyRouteQuery>(gql`
    query CompanyRouteQuery($id: ID!) {
      company(id: $id) {
        id
        name
      }

      companies {
        id
      }

      user: viewer {
        ...Topbar_user
      }
    }

    ${Topbar.fragments.user}
  `, {
    variables: { id: companyId as string }
  })

  function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
  }

  const onLanguageChange = (event) =>{
    const selectElement = event.currentTarget;
    const option = selectElement.options[selectElement.selectedIndex];
    document.cookie = "language=" + encodeURIComponent(option.value) + "; path=/";
    window.location.reload(true)
  }

  const onGoToLicense = (companyId: string) => {
    if (companyId && companyId != '0') {
      window.location = "/" + companyId + "/manage/bot_user_licenses"
    }
  }
  const onGoToLicenseSa = (companyId: string) => {
    if (companyId && companyId != '0') {
      window.location = "/" + companyId + "/manage/licenses"
    }
  }

  const goToLicense = (companyId: string) => {
    if (companyId && companyId != '0') {
      window.location = "/shop/companies/" + companyId + "/departments"
    } else {
      window.location = "/shop/departments"
    }
  }

  const shopPopupUi = (companyId: string) => {
    return (
      <div className="relative z-10" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
              <div className="bg-white p-6 pb-0">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <h3 className="text-base font-semibold leading-6 text-gray-900" id="modal-title">{t('redirect_header')}</h3>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                      {t('redirect_explanation')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="p-6 sm:flex sm:flex-row-reverse sm:px-6">
                <button type="button" onClick={(event) => onGoToStore(companyId)} className="inline-flex items-center border shadow-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 border-transparent text-white bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 disabled:bg-blue-300 disabled:hover:bg-blue-200 px-4 py-2 text-sm rounded-md">{t('links.shop')}</button>
                <button type="button" onClick={(event) => setShowPopup(null)} className="mr-4 text-gray-900 bg-white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-200 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700">{t('links.back')}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  var userRole = data?.user?.role

  const [showVerzendenMenu, setShowVerzendenMenu] = React.useState<boolean>(false);
  const [showLicenseMenu, setShowLicenseMenu] = React.useState<boolean>(false);
  const [showBouwenMenu, setShowBouwenMenu] = React.useState<boolean>(false);
  const [showInsightMenu, setShowInsightMenu] = React.useState<boolean>(false);
  const [showStore, setShowStore] = React.useState<boolean>(false);
  const [showAdmin, setShowAdmin] = React.useState<boolean>(false);

  const boxShadowStyle = '0 2px 16px rgba(69,51,68,.16), 0 4px 10px rgba(69,51,68,.16)'

  return (
    <React.Fragment>
      <div className="main-navigation bg-white shadow text-gray-900 text-sm font-medium">
        <Topbar
          user={data?.user}
          navLinks={
            <React.Fragment>
              <TopbarNavLink to="">
                <div className='flex items-center relative px-4'>
                  {t('menu_home')}
                  <span title={t('menu_home')}  className="ml-2 material-symbols-outlined">
                    home
                  </span>
                </div>
              </TopbarNavLink>

              {data?.user?.role != 'supervisor' &&
                <div className={showBouwenMenu ? 'dropdown relative': 'dropdown relative'} style={{boxShadow: showBouwenMenu ? boxShadowStyle : ''}} onMouseEnter={()=>setShowBouwenMenu(true)} onClick={()=>setShowBouwenMenu(false)} onMouseLeave={()=>setShowBouwenMenu(false)}>
                  <div className='dropdown-toggle flex items-center px-4 bg-white relative' style={{height: '62px', zIndex: showBouwenMenu ? 41 : 0}}>
                    <div className='flex justify-center items-center'>
                      {t('menu_build')}
                      <span title={t('menu_build')} className="ml-2 material-symbols-outlined">
                        build
                      </span>
                    </div>
                  </div>
                  {showBouwenMenu &&
                    <div className='py-2 px-2 dropdown-menu bg-white shadow absolute min-w-full left-0 z-40' style={{top: '62px', boxShadow: boxShadowStyle}}>
                      <TopbarNavLink to="bot_modules">
                        <div className='flex justify-between items-center align-center px-4'>
                          {t('menu_bot_modules')}
                          <span title={t('menu_bot_modules')} className="ml-2 material-symbols-outlined">
                            chat
                          </span>
                        </div>
                      </TopbarNavLink>
                      <TopbarNavLink to="qa_modules">
                        <div className='flex justify-between items-center align-center px-4'>
                          {t('menu_qa_modules')}
                          <span title={t('menu_qa_modules')} className="ml-2 material-symbols-outlined">
                            neurology
                          </span>
                        </div>
                      </TopbarNavLink>
                    </div>
                  }
                </div>
              }

              {(data?.user?.role == 'superadmin' || data?.user?.role == 'admin' || userRole == 'supervisor') &&
                <div className={showLicenseMenu ? 'dropdown relative': 'dropdown relative'} style={{boxShadow: showLicenseMenu ? boxShadowStyle : ''}} onMouseEnter={()=>setShowLicenseMenu(true)} onClick={()=>setShowLicenseMenu(false)} onMouseLeave={()=>setShowLicenseMenu(false)}>
                  <div className='dropdown-toggle flex items-center px-4 bg-white relative' style={{height: '62px', zIndex: showLicenseMenu ? 41 : 0}}>
                    <div className='flex justify-center items-center'>
                      {t('menu_license_title')}
                      <span title={t('menu_license_title')} className="ml-2 material-symbols-outlined">
                        license
                      </span>
                    </div>
                  </div>
                  {showLicenseMenu &&
                    <div className='py-2 px-2 dropdown-menu bg-white shadow absolute min-w-full left-0 z-40' style={{top: '62px', boxShadow: boxShadowStyle}}>
                      {(data?.user?.role == 'superadmin' || data?.user?.role == 'admin') &&
                        <>
                          <TopbarNavLink to="departments"  onClick={(event) => onGoToLicense(companyId)}>
                            <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                              {t('menu_licenses')}
                              <span title={t('menu_licenses')} className="ml-2 material-symbols-outlined">
                                id_card
                              </span>
                            </div>
                          </TopbarNavLink>
                          {data?.user?.role == 'superadmin' &&
                            <TopbarNavLink to="departments"  onClick={(event) => onGoToLicenseSa(companyId)}>
                              <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                                {t('menu_license')}
                                <span title={t('menu_license')} className="ml-2 material-symbols-outlined">
                                  license
                                </span>
                              </div>
                            </TopbarNavLink>
                          }
                        </>
                      }
                    </div>
                  }
                </div>
              }

              {(data?.user?.role == 'superadmin' || data?.user?.role == 'admin' || userRole == 'supervisor') &&
                <div className={showVerzendenMenu ? 'dropdown relative': 'dropdown relative'} style={{boxShadow: showVerzendenMenu ? boxShadowStyle : ''}} onMouseEnter={()=>setShowVerzendenMenu(true)} onClick={()=>setShowVerzendenMenu(false)} onMouseLeave={()=>setShowVerzendenMenu(false)}>
                  <div className='dropdown-toggle flex items-center px-4 bg-white relative' style={{height: '62px', zIndex: showVerzendenMenu ? 41 : 0}}>
                    <div className='flex justify-center items-center'>
                      {t('menu_send')}
                      <span title={t('menu_send')} className="ml-2 material-symbols-outlined">
                        arrow_forward
                      </span>
                    </div>
                  </div>
                  {showVerzendenMenu &&
                    <div className='py-2 px-2 dropdown-menu bg-white shadow absolute min-w-full left-0 z-40' style={{top: '62px', boxShadow: boxShadowStyle}}>
                      <TopbarNavLink to="bot_users">
                        <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                          {t('menu_users')}
                          <span title={t('menu_users')} className="ml-2 material-symbols-outlined">
                            group
                          </span>
                        </div>
                      </TopbarNavLink>
                      {(data?.user?.role == 'superadmin' || data?.user?.role == 'admin') &&
                        <TopbarNavLink to="departments">
                          <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                            {t('menu_department')}
                            <span title={t('menu_department')} className="ml-2 material-symbols-outlined">
                              license
                            </span>
                          </div>
                        </TopbarNavLink>
                      }
                      {(data?.user?.role == 'superadmin' || data?.user?.role == 'admin') &&
                        <TopbarNavLink to="whatsapp_broadcasts">
                          <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                            {t('menu_push')}
                            <span title={t('menu_push')} className="ml-2 material-symbols-outlined">
                            send
                            </span>
                          </div>
                        </TopbarNavLink>
                      }
                    </div>
                  }
                </div>
              }

              {(userRole == 'admin' ||  userRole == 'superadmin' || userRole == 'supervisor') &&
                <div className={showInsightMenu ? 'dropdown relative': 'dropdown relative'} style={{boxShadow: showInsightMenu ? boxShadowStyle : ''}} onMouseEnter={()=>setShowInsightMenu(true)} onClick={()=>setShowInsightMenu(false)} onMouseLeave={()=>setShowInsightMenu(false)}>
                  <div className='dropdown-toggle flex items-center px-4 bg-white relative' style={{height: '62px', zIndex: showInsightMenu ? 41 : 0}}>
                    <div className='flex justify-center items-center'>
                      {t('menu_insight')}
                      <span title={t('menu_insight')} className="ml-2 material-symbols-outlined">
                        search
                      </span>
                    </div>
                  </div>
                  {showInsightMenu &&
                    <div className='py-2 px-2 dropdown-menu bg-white shadow absolute min-w-full left-0 z-40' style={{top: '62px', boxShadow: boxShadowStyle}}>
                      <TopbarNavLink to="question_analytics">
                        <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                          {t('menu_q_a')}
                          <span title={t('menu_q_a')} className="ml-2 material-symbols-outlined">
                            monitoring
                          </span>
                        </div>
                      </TopbarNavLink>
                      <a className="px-3 text-gray-900 text-sm font-medium" href={"/" + companyId + "/new_report"}>
                        <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                          {t('menu_report')}
                          <span title={t('menu_report')} className="ml-2 material-symbols-outlined">
                            dashboard
                          </span>
                        </div>
                      </a>
                    </div>
                  }
                </div>
              }

              <div className={showStore ? 'dropdown relative': 'dropdown relative'} style={{boxShadow: showStore ? boxShadowStyle : ''}} onMouseEnter={()=>setShowStore(true)} onClick={()=>setShowStore(false)} onMouseLeave={()=>setShowStore(false)}>
                <div className='dropdown-toggle flex items-center px-4 bg-white relative' style={{height: '62px', zIndex: showStore ? 41 : 0}}>
                  <div className='flex justify-center items-center'>
                    {t('menu_academy')}
                    <span title={t('menu_academy')} className="ml-2 material-symbols-outlined">
                      school
                    </span>
                  </div>
                </div>
                {showStore &&
                  <div className='py-2 px-2 dropdown-menu bg-white shadow absolute min-w-full left-0 z-40' style={{top: '62px', boxShadow: boxShadowStyle}}>
                    <TopbarNavLink to={`#`} onClick={(event) => setShowPopup(companyId)}>
                      <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                        {t('menu_academy_private')}
                        <span title={t('menu_academy_private')} className="ml-2 material-symbols-outlined">
                          shopping_cart
                        </span>
                      </div>
                    </TopbarNavLink>
                    <TopbarNavLink to={`#`} onClick={(event) => setShowPopup("")}>
                      <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                        {t('menu_academy_public')}
                        <span title={t('menu_academy_public')} className="ml-2 material-symbols-outlined">
                          store
                        </span>
                      </div>
                    </TopbarNavLink>
                  </div>
                }
              </div>

              { data?.user?.role == 'superadmin' &&
                <div className={showAdmin ? 'dropdown relative': 'dropdown relative'} style={{boxShadow: showAdmin ? boxShadowStyle : ''}} onMouseEnter={()=>setShowAdmin(true)} onClick={()=>setShowAdmin(false)} onMouseLeave={()=>setShowAdmin(false)}>
                  <div className='dropdown-toggle flex items-center px-4 bg-white relative' style={{height: '62px', zIndex: showAdmin ? 41 : 0}}>
                    <div className='flex justify-center items-center'>
                      Admin
                      <span title="Tools" className="ml-2 material-symbols-outlined">
                        lock
                      </span>
                    </div>
                  </div>
                  {showAdmin &&
                    <div className='py-2 px-2 dropdown-menu bg-white shadow absolute min-w-full left-0 z-40' style={{top: '62px', boxShadow: boxShadowStyle}}>
                      <TopbarNavLink to={`orders`}>
                        <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                          {t('menu_orders')}
                          <span title={t('menu_orders')} className="ml-2 material-symbols-outlined">
                          money
                          </span>
                        </div>
                      </TopbarNavLink>
                      <TopbarNavLink to="users">
                        <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                          {t('menu_admin_users')}
                          <span title={t('menu_admin_users')} className="ml-2 material-symbols-outlined">
                            admin_panel_settings
                          </span>
                        </div>
                      </TopbarNavLink>
                      <TopbarNavLink to="subscriptions/new" state={{ backgroundLocation: location }}>
                        <div className='flex justify-between items-center align-center px-4 whitespace-nowrap'>
                          {t('menu_subscriptions')}
                          <span title={t('menu_subscriptions')} className="ml-2 material-symbols-outlined">
                          subscriptions
                          </span>
                        </div>
                      </TopbarNavLink>
                    </div>
                  }
                </div>
              }

            </React.Fragment>
          }
          menuLinks={
          <>
            <div className="px-4 py-3">
              <p className="text-xs text-gray-500">Taal</p>
              <select className="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md" defaultValue={window.locale} name="languages" id="languages_switch" onChange={(evt) => onLanguageChange(evt)}>
                <option value="nl">Nl</option>
                <option value="en">EN</option>
              </select>
            </div>
            <React.Fragment>
              <div className="px-4 py-3">
                {data?.company && (
                  <React.Fragment>
                    <p className="text-xs text-gray-500">{t('viewing_company')}</p>
                    <p className="text-sm font-medium text-gray-900 truncate">
                      {data.company.name}
                    </p>
                  </React.Fragment>
                )}
              </div>

              <Menu.Item>
                {({ active }) => (
                  <Link to="/companies" className={cx(active ? 'text-blue-600' : 'text-gray-700', 'group flex items-center w-full text-left px-4 py-2 text-sm')}>
                    <SwitchHorizontalIcon className="flex-none mr-3 h-5 w-5 text-gray-400 group-hover:text-blue-600" aria-hidden="true" />
                    {t('switch_company')}
                  </Link>
                )}
              </Menu.Item>
              {(userRole == 'admin' ||  userRole == 'superadmin') &&
                <Menu.Item>
                  {({ active }) => (
                    <Link to="settings" className={cx(active ? 'text-blue-600' : 'text-gray-700', 'group flex items-center w-full text-left px-4 py-2 text-sm')}>
                      <CogIcon className="flex-none mr-3 h-5 w-5 text-gray-400 group-hover:text-blue-600" aria-hidden="true" />
                      {t('settings')}
                    </Link>
                  )}
                </Menu.Item>
              }
            </React.Fragment>
          </>
          }
        />
      </div>

      {showPopup != null && shopPopupUi(showPopup)}
      <div className="py-10">
        <div className="max-w-6xl mx-auto px-8">
          {loading ? (
            <p>{t('loading')}</p>
          ) : error ? (
            <p>{t('error')}</p>
          ) : !data!.company ? (
            <p>{t('not_found')}</p>
          ) : (
            <Routes company={data!.company!} userRole={userRole} />
          )}
        </div>
      </div>

      {data?.company && (
        <ModalRoutes company={data.company} userRole={userRole} />
      )}
    </React.Fragment>
  );
}

export default CompanyRoute;
